import React from 'react';

import { Button } from 'src/components/atoms/Button';
import { InlineList } from 'src/components/molecules/InlineList';
import { Link } from 'src/containers/Link';
import { FluidImg } from 'src/embeds/image/image-dato';
import * as css from './drawer-card-grid.css';
import * as sys from '../../system';

export default function Drawer({ heading, content, contentNode, callouts, ctas }) {
  const gridCards = callouts.filter((callout) => callout.__typename == 'DatoCmsGridCard');

  return (
    <section css={[css.section]}>
      <div css={[sys.wrapLast, sys.frame]}>
        <h2 css={[css.sectionHeading]}>{heading}</h2>
        {content.length ? (
          <div
            css={[css.content]}
            dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }}
          />
        ) : null}
        {gridCards.length > 0 ? (
          <div css={[css.grid]}>
            {gridCards.map(({ heading, preHeading, contentNode, media, url, cta, headerFit }, i) => {
              const imageStyle = media.customData || {}
              return (
                <Link css={[css.cell, css[`cell${i + 1}`]]} className={`cell-${i + 1}`} to={url} key={heading}>
                <article css={[css.article]} className="card-article">
                  <header className="card-header">
                    <FluidImg
                      style={imageStyle}
                      css={[css.cardImage]}
                      data={media}
                      pictureStyle={{ objectFit: headerFit === 'full' ? 'cover' : 'contain' }}
                    />
                  </header>
                  <div className="card-main" css={[css.cardMain]}>
                    <p css={[css.metaHeading]} className="f-mono">
                      {preHeading}
                    </p>
                    <h3 css={[css.heading]} className="heading">
                      {heading}
                    </h3>
                    {contentNode && (
                      <div
                        css={[css.content]}
                        dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }}
                      />
                    )}
                    <p className="cta" css={[css.cta]}>
                      {cta} →
                    </p>
                  </div>
                </article>
              </Link>
              )
            })}
          </div>
        ) : null}
        {/**
         * this area below is not being used
         */}
        {ctas.length > 0 && (
          <InlineList centered style={{ width: '100%', paddingTop: 80 }}>
            {ctas.map((cta, i) => {
              const ctaFlags = i > 0 ? { secondary: true } : {};
              return (
                <li key={i}>
                  <Button to={cta.url} {...ctaFlags}>
                    {cta.text}
                  </Button>
                </li>
              );
            })}
          </InlineList>
        )}
      </div>
    </section>
  );
}
